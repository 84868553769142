*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0px
}

.left-0 {
  left: 0px
}

.col-span-3 {
  grid-column: span 3 / span 3
}

.col-span-2 {
  grid-column: span 2 / span 2
}

.float-right {
  float: right
}

.m-4 {
  margin: 1rem
}

.m-2 {
  margin: 0.5rem
}

.m-0 {
  margin: 0px
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem
}

.mr-4 {
  margin-right: 1rem
}

.ml-4 {
  margin-left: 1rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mb-7 {
  margin-bottom: 1.75rem
}

.mt-7 {
  margin-top: 1.75rem
}

.mr-7 {
  margin-right: 1.75rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-11 {
  margin-top: 2.75rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mt-8 {
  margin-top: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mb-9 {
  margin-bottom: 2.25rem
}

.mb-14 {
  margin-bottom: 3.5rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-16 {
  margin-top: 4rem
}

.mb-16 {
  margin-bottom: 4rem
}

.mb-20 {
  margin-bottom: 5rem
}

.mt-9 {
  margin-top: 2.25rem
}

.mt-14 {
  margin-top: 3.5rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-11 {
  margin-bottom: 2.75rem
}

.ml-6 {
  margin-left: 1.5rem
}

.mr-8 {
  margin-right: 2rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mr-0 {
  margin-right: 0px
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.ml-0 {
  margin-left: 0px
}

.mt-20 {
  margin-top: 5rem
}

.mb-40 {
  margin-bottom: 10rem
}

.mr-11 {
  margin-right: 2.75rem
}

.mt-12 {
  margin-top: 3rem
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.h-9 {
  height: 2.25rem
}

.h-4 {
  height: 1rem
}

.h-screen {
  height: 100vh
}

.h-full {
  height: 100%
}

.h-auto {
  height: auto
}

.w-1\/2 {
  width: 50%
}

.w-full {
  width: 100%
}

.w-24 {
  width: 6rem
}

.w-64 {
  width: 16rem
}

.w-10 {
  width: 2.5rem
}

.w-4 {
  width: 1rem
}

.w-40 {
  width: 10rem
}

.w-screen {
  width: 100vw
}

.w-80 {
  width: 20rem
}

.w-60 {
  width: 15rem
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/3 {
  width: 33.333333%
}

.max-w-lg {
  max-width: 32rem
}

.max-w-xs {
  max-width: 20rem
}

.max-w-sm {
  max-width: 24rem
}

.grow {
  flex-grow: 1
}

.rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr))
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-items-start {
  justify-items: start
}

.gap-4 {
  gap: 1rem
}

.gap-y-9 {
  row-gap: 2.25rem
}

.gap-x-4 {
  -webkit-column-gap: 1rem;
          column-gap: 1rem
}

.gap-y-6 {
  row-gap: 1.5rem
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded {
  border-radius: 0.25rem
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-solid {
  border-style: solid
}

.border-none {
  border-style: none
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity))
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity))
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity))
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity))
}

.p-4 {
  padding: 1rem
}

.p-1 {
  padding: 0.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-16 {
  padding: 4rem
}

.p-0 {
  padding: 0px
}

.p-8 {
  padding: 2rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem
}

.pl-16 {
  padding-left: 4rem
}

.pt-7 {
  padding-top: 1.75rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pl-4 {
  padding-left: 1rem
}

.pr-7 {
  padding-right: 1.75rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pb-7 {
  padding-bottom: 1.75rem
}

.pl-10 {
  padding-left: 2.5rem
}

.pr-14 {
  padding-right: 3.5rem
}

.pb-24 {
  padding-bottom: 6rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-6 {
  padding-top: 1.5rem
}

.pl-6 {
  padding-left: 1.5rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pl-9 {
  padding-left: 2.25rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pb-9 {
  padding-bottom: 2.25rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pt-8 {
  padding-top: 2rem
}

.pb-14 {
  padding-bottom: 3.5rem
}

.pr-16 {
  padding-right: 4rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-40 {
  padding-bottom: 10rem
}

.text-center {
  text-align: center
}

.align-top {
  vertical-align: top
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.font-semibold {
  font-weight: 600
}

.font-medium {
  font-weight: 500
}

.lowercase {
  text-transform: lowercase
}

.italic {
  font-style: italic
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.outline {
  outline-style: solid
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity))
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity))
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity))
}
